<template>
  <section class="booking--detail-survey" v-show="isAllowed">
    <div class="left--part">
      <!--      <div class="title&#45;&#45;text">-->
      <!--        Reschedule-->
      <!--      </div>-->
      <div>
        {{ $t('booking.tourSect.reqHeader') }}
        <div>
          {{ previousDate }}
        </div>
      </div>
    </div>
    <div class="right--part">
      <!--      <div class="form&#45;&#45;group">-->
      <!--        <div class="left&#45;&#45;col">Jadwal Survei</div>-->
      <!--        <div class="right&#45;&#45;col"></div>-->
      <!--      </div>-->
      <div class="form--group">
        <!--          <button class="btn btn-primary" @click="showModal('modal-reschedule-tour-confirmation')">-->
        <!--            Reschedule Tour-->
        <!--          </button>-->
        <reject-tour-btn
          :application-uuid="applicationUuid"
          :allowed-actions="allowedActions"
          :booking-info="bookingInfo"
          :type="type"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
const RejectTourBtn = () => import('@/components/application-action/buttons/tour-prog/reject-btn');

export default {
  name: 'reject-only-tour-section',
  components: { RejectTourBtn },
  props: {
    allowedActions: {
      required: true,
      validator: (value) => {
        if (value !== null) {
          return value.constructor.name === 'Array';
        } else {
          return true;
        }
      },
    },
    type: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    applicationUuid: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    bookingInfo: {
      required: true,
    },
  },
  data() {
    return {
      actionName: 'REJECT',
      actionType: 'TOUR_PROG',
      previousDate: null,
    };
  },
  watch: {
    isAllowed(value) {
      if (value) {
        this.fetchData();
      }
    },
  },
  computed: {
    ...mapState({
      time: (state) => state.booking.initiateTour.time,
    }),
    isAllowed() {
      if (!this.applicationUuid) {
        return false;
      }
      const applicationType = this.type.toUpperCase(),
        actionType = this.actionType.toUpperCase(),
        actionName = this.actionName.toUpperCase();
      let allowedActions = [];
      if (this.allowedActions) {
        allowedActions = this.allowedActions.map((elem) => {
          return elem.name.toUpperCase();
        });
      }
      return (
        applicationType === actionType &&
        allowedActions.indexOf(actionName) !== -1 &&
        allowedActions.indexOf('RESCHEDULE') === -1
      );
    },
  },
  methods: {
    async fetchData() {
      const { data } = await this.$http.get(
        '/api/v2/get_application_action_data/tour_schedule_prog',
        {
          params: {
            applicationUuid: this.applicationUuid,
          },
        },
      );
      this.previousDate = data.data.applicationDate;
    },
    showModal(modalName) {
      this.$modal.show(modalName);
    },
  },
};
</script>

<style scoped></style>
